var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col"},[_c('label',{staticClass:"block text-sm text-gray md:text-left mb-1 pr-4 truncate",class:{ 'text-error': _vm.error, 'mb-1': _vm.label }},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.mandatory)?_c('span',{staticClass:"text-error"},[_vm._v("*")]):_vm._e()]),_c('div',{staticClass:"relative text-base w-full"},[_c('div',{staticClass:"flex items-center border rounded-md",class:{
        'border border-gray-lightest bg-gray-lightest text-gray-light cursor-not-allowed':
          _vm.disabled,
        'bg-white border hover:shadow-primary-md': !_vm.disabled,
        'border-primary': !_vm.isFocus,
        'hover:shadow-secondary-sm shadow-secondary-sm border-secondary':
          _vm.isFocus,
        'border-error text-error': _vm.error,
        'text-sm h-11': this.size == 'small',
        'text-base h-12': this.size == 'medium',
        'text-base h-14': this.size == 'large',
      }},[(_vm.iconLeft)?_c('div',{staticClass:"absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none",class:{
          'text-base': this.size == 'small',
          'text-2xl': this.size == 'medium',
          'text-2xl': this.size == 'large',
        }},[_c('span',{class:_vm.iconLeft})]):_vm._e(),_c('input',{staticClass:"w-full border-none focus:ring-0 rounded-md placeholder-gray-light text-gray",class:{
          'pl-8': _vm.iconLeft,
          ' bg-gray-lightest text-gray-light cursor-not-allowed': _vm.disabled,
        },attrs:{"type":"text","placeholder":_vm.placeholder,"disabled":_vm.disabled,"readonly":_vm.readOnly},domProps:{"value":_vm.search},on:{"input":_vm.onChange,"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"])){ return null; }return _vm.onArrowDown.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"])){ return null; }return _vm.onArrowUp.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onEnter.apply(null, arguments)}],"focus":function($event){_vm.isFocus = !_vm.isFocus},"blur":function($event){_vm.isFocus = !_vm.isFocus}}}),_c('div',{staticClass:"text-base pr-2 items-center flex inset-y-0 right-0 text-center",class:{
          'text-base h-11': this.size == 'small',
          'text-2xl h-12': this.size == 'medium',
          'text-2xl h-14': this.size == 'large',
        }},[_c('svg',{staticClass:"h-4 w-4 transform transition-transform duration-200 ease-in-out",class:!_vm.readOnly && _vm.isOpen ? 'rotate-180' : 'rotate-0',attrs:{"fill":"none","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M19 9l-7 7-7-7"}})])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}],ref:"scrollSearch",staticClass:"absolute left-0 right-0 mb-4 rounded-md bg-white shadow-primary-sm z-50",staticStyle:{"max-height":"300px","overflow":"auto"},on:{"scroll":_vm.eventScroll}},[_c('ul',[(_vm.results.length < 1 && !_vm.isLoading)?_c('li',{staticClass:"px-3 py-2 transition-colors duration-100 text-sm text-gray hover:bg-secondary hover:text-white"},[_vm._t("emptyResult",function(){return [_vm._v(" No item found ")]})],2):_vm._l((_vm.results),function(result,i){return _c('li',{key:i,staticClass:"px-3 py-2 transition-colors duration-100 text-sm text-gray hover:bg-secondary hover:text-white",on:{"click":function($event){return _vm.setResult(result)}}},[_vm._t("option",function(){return [_vm._v(" "+_vm._s(typeof result === "object" ? result[_vm.textField] : result)+" ")]},{"slotProps":result})],2)}),(_vm.isLoading)?_c('li',{staticClass:"px-3 py-2 transition-colors duration-100 text-sm text-center"},[_vm._t("loading",function(){return [_vm._v(" Sedang Ambil Data... ")]})],2):_vm._e()],2)]),(_vm.hint && !_vm.dense)?_c('div',{staticClass:"text-xs mt-1"},[_vm._v(" "+_vm._s(_vm.hint)+" ")]):_vm._e(),(_vm.error)?_c('div',{staticClass:"text-danger text-xs"},[_vm._t("message")],2):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }
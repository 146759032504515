<template>
  <div class="flex flex-col">
    <div class="flex items-center">
      <label
        class="block text-sm text-gray font-medium"
        :class="{ 'text-error': error, 'mb-1': label, truncate: truncateLabel }"
      >
        {{ label }} <span v-if="mandatory" class="text-error">*</span>
      </label>
    </div>
    <div
      class="pl-0 rounded-lg border items-center"
      :class="[
        {
          ' grid grid-cols-4 border':
            this.$slots['select-item-left'] != undefined ||
            this.$slots['select-item-right'],
        },
        {
          flex:
            this.$slots['select-item-left'] == undefined ||
            this.$slots['select-item-right'] == undefined,
        },
        {
          'border-secondary shadow-secondary-round hover:shadow-secondary-round':
            isFocus,
        },
        { 'bg-gray-lightest': this.disabled },
        { 'border-primary hover:shadow-primary-sm ': !error && !this.disabled },
        { 'border-gray-lightest': this.disabled },
        { 'border-error hover:shadow-primary-sm': error },
        {
          'text-small h-11': this.size == 'small',
          'text-base h-12': this.size == 'medium',
          'text-base h-14': this.size == 'large',
        },
        // {
        //   'ml-1 mr-1':
        //     this.$slots['icon-left'] == undefined &&
        //     this.$slots['icon-right'] == undefined &&
        //     this.$slots['select-item'] == undefined,
        // },
        // {
        //   'ml-1 mr-1':
        //     this.$slots['icon-left'] != undefined
        //       ? this.$slots['icon-left'][0].children == undefined
        //         ? true
        //         : false
        //       : false,
        // },
        // {
        //   'ml-1 mr-2':
        //     this.$slots['icon-right'] != undefined
        //       ? this.$slots['icon-right'][0].children == undefined
        //         ? true
        //         : false
        //       : false,
        // },
      ]"
    >
      <!-- slot untuk icon left -->
      <div
        class="inset-y-0 items-center flex left-0 pl-2"
        v-if="this.$slots['icon-left'] != undefined"
        :class="[
          {
            'text-base': this.size == 'small',
            'text-2xl': this.size == 'medium',
            'text-2xl': this.size == 'large',
            'cursor-pointer':
              this.$slots['icon-left'] != undefined
                ? this.$slots['icon-left'][0].data.on != undefined
                : false,
          },
        ]"
      >
        <slot name="icon-left"></slot>
      </div>
      <!-- end-->

      <!-- slot untuk select -->
      <div
        class="inset-y-0 items-center left-0 pl-0 w-full col-span-1"
        v-if="this.$slots['select-item-left'] != undefined"
        :class="[
          {
            'text-base': this.size == 'small',
            'text-2xl': this.size == 'medium',
            'text-2xl': this.size == 'large',
          },
        ]"
      >
        <slot name="select-item-left"></slot>
      </div>
      <!-- <div
        class="w-full"
        :class="{
          'col-span-3':
            this.$slots['select-item-left'] != undefined ||
            this.$slots['select-item-right'] != undefined,
        }"
      >
        <input
          @input="handleInput"
          :placeholder="placeholder"
          :type="type"
          class="w-full border-none focus:ring-0 rounded-lg placeholder-gray-light text-gray"
          @focus="isFocus = true"
          @blur="isFocus = false"
          @keypress="disabledWhiteSpace"
          @keydown="disabledWhiteSpace"
          :maxlength="maxlength"
          :class="[
            { 'text-sm': this.size == 'small' },
            { 'h-11': this.size == 'large' },
            {
              'bg-gray-lightest text-gray-darkest': this.disabled,
            },
            { 'cursor-not-allowed': this.disabled || this.readonly },
            // { 'col-span-2': this.$slots['select-item-left'] != undefined },
          ]"
          :disabled="disabled"
          :readonly="readonly"
          :value="value"
          :ref="ref"
        />
      </div> -->
      <input
        @input="handleInput"
        :placeholder="placeholder"
        :type="type"
        class="w-full border-none focus:ring-0 h-full rounded-lg placeholder-gray-light text-gray"
        @focus="isFocus = true"
        @blur="isFocus = false"
        @keypress="disabledWhiteSpace"
        @keydown="disabledWhiteSpace"
        :class="[
          {
            'bg-gray-lightest text-gray-light': this.disabled,
          },
          { 'cursor-not-allowed': this.disabled || this.readonly },
          { 'col-span-2': this.$slots['select-item'] != undefined },
        ]"
        :disabled="disabled"
        :readonly="readonly"
        :maxlength="maxlength"
        :value="value"
        :ref="ref"
      />
      <div
        class="text-base pr-2 items-center flex inset-y-0 right-0 text-center"
        v-if="this.$slots['icon-right'] != undefined"
        :class="[
          {
            'text-2xl':
              this.$slots['icon-right'] != undefined
                ? this.$slots['icon-right'][0].data.class != undefined
                  ? true
                  : false
                : false,
            'cursor-pointer':
              this.$slots['icon-right'] != undefined
                ? this.$slots['icon-right'][0].data.on != undefined
                : false,
            'bg-primary text-white rounded-r-lg w-28':
              this.$slots['icon-right'] != undefined
                ? this.$slots['icon-right'][0].children != undefined
                  ? true
                  : false
                : false,
          },
          {
            'h-11': this.size == 'small',
            'h-12': this.size == 'medium',
            'h-14': this.size == 'large',
          },
        ]"
      >
        <slot name="icon-right"></slot>
      </div>
      <div
        class="inset-y-0 items-center border-border-none right-0 pr-0 w-full col-span-1"
        v-if="this.$slots['select-item-right'] != undefined"
        :class="[
          {
            'text-2xl': this.size == 'small',
            'text-2xl': this.size == 'large',
          },
        ]"
      >
        <slot name="select-item-right"></slot>
      </div>
    </div>
    <div
      v-if="this.$slots['message'] != undefined"
      class="text-xs mt-1"
      :class="[{ 'text-error': error }]"
    >
      <slot name="message"></slot>
    </div>
  </div>
</template>
<script>
/*
  <input-gista
    v-validate="'required|max:255'"
    :error="errors.has('vendor_name')"
    data-vv-as="Nama Vendor"
    name="vendor_name"
    size="large"
    placeholder="isi Nama Vendor"
    type="text"
    v-model="form.vendor_name"
    label="Nama Vendor*"
    :disabled="true"
  >
    <div v-if="errors.has('vendor_name')" slot="message">
      {{ errors.first("vendor_name") }}
    </div>
    <div v-if="!form.vendor_name" slot="message" class="text-gray">
      Wajib isi kode vendor terlebih dahulu
    </div>
  </input-gista>
*/

export default {
  name: "InputGista",
  props: {
    // untuk v-model
    value: String,
    // for font size, use large
    size: {
      type: String,
      default: "medium",
    },
    placeholder: String,
    // for input type
    type: String,
    // error flag
    error: Boolean,
    label: String,
    disabled: Boolean,
    readonly: Boolean,
    maxlength: Number,
    // input ref
    ref: String,
    // don't use
    removeWhiteSpace: Boolean,
    mandatory: Boolean,
    truncateLabel: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isFocus: false,
    };
  },
  methods: {
    disabledWhiteSpace(e) {
      if (this.removeWhiteSpace) {
        if (e.keyCode == 32 || e.keyCode == 86 || e.keyCode == 17) {
          e.preventDefault();
          return;
        }
      }
    },
    handleInput(e) {
      this.$emit("input", e.target.value);
    },
  },
};
</script>

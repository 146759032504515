<template>
  <Transition name="fade">
    <div
      v-if="value"
      class="fixed inset-0 w-full flex items-center justify-center bg-black-60 backdrop-filter backdrop-blur-sm z-100"
      :class="{
        'h-screen': !scrollable,
        'h-full overflow-auto': !scrollable,
      }"
    >
      <div
        :style="styleObject"
        class="shadow-lg p-2 z-50"
        :class="[
          {
            'relative overflow-visible': !scrollable,
            'rounded-lg': !fullScreen,
            'bg-white': !customClass,
            'fixed max-h-screen overflow-x-hidden': scrollable,
          },
          computedWidth,
          customClass,
        ]"
      >
        <button
          aria-label="close"
          class="absolute top-0 right-0 text-xl font-bold text-gray-500 my-2 mx-4 text-secondary hover:text-secondary-dark"
          v-if="!hideClose"
          @click.prevent="!hideClose && close()"
        >
          <span class="icon-x"></span>
        </button>
        <slot />
      </div>
    </div>
  </Transition>
</template>

<script>
/*
  <gista-modal
    width="1/3"
    v-model="showPopup"
    :hide-close="true"
    :full-screen="false"
  ></gista-modal>
*/

export default {
  name: "GistaModal",
  props: {
    width: {
      // modal width, akan mengenerate class w-${width}, hanya berlaku untuk desktop
      type: String,
      default: "1/2",
    },
    fullScreen: {
      // if true, maka modal akan fullscreen
      type: Boolean,
      default: false,
    },
    hideClose: {
      // if true, icon close akan terhide
      type: Boolean,
      default: false,
    },
    value: {
      // untuk v-model modal (tampil atau tidak)
      type: Boolean,
      default: false,
    },
    customClass: {
      // custom class untuk modal
      type: String,
    },
    scrollable: {
      // jika modal bisa scrollable
      type: Boolean,
      default: false,
    },
  },
  computed: {
    computedWidth() {
      return this.fullScreen ? "w-full h-full" : `w-11/12 lg:w-${this.width}`;
    },
    styleObject() {
      if (!this.fullScreen) {
        return {
          maxHeight: "calc(100vh - 30px)",
        };
      }
      return null;
    },
  },
  watch: {
    value(value) {
      if (value) {
        return document.querySelector("body").classList.add("overflow-hidden");
      }

      document.querySelector("body").classList.remove("overflow-hidden");
    },
  },
  methods: {
    close() {
      this.$emit("input", false);
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

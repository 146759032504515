<template>
  <div class="flex items-center my-2">
    <input
      type="checkbox"
      class="checked: focus:ring-white rounded-md"
      :class="{
        'bg-gray-lightest border-gray-lightest text-gray-lightest cursor-not-allowed':
          disabled || readOnly,
        'h-6 w-6 text-base': size == 'md',
        'h-8 w-8 text-lg': size == 'lg',
        'h-10 w-10 text-xl': size == 'xl',
        'border border-primary hover:shadow-primary-sm cursor-pointer text-primary':
          !disabled || !readOnly,
      }"
      :disabled="disabled || readOnly"
      :checked="isChecked"
      :true-value="trueValue"
      :false-value="falseValue"
      :readonly="readOnly"
      @change="!readOnly && updateInput($event)"
    />
    <span
      class="ml-2"
      :class="{
        'text-base': size == 'md',
        'text-lg': size == 'lg',
        'text-xl': size == 'xl',
      }"
      ><slot
    /></span>
  </div>
</template>
<script>
/*
  <gista-checkbox v-model="is_select_all" @change="selectAll"
    >Select All</gista-checkbox
  >
*/

export default {
  name: "GistaCheckbox",
  model: {
    prop: "modelValue",
    event: "change",
  },
  props: {
    size: {
      // md, lg, xl
      type: String,
      default: "md",
    },
    modelValue: {
      default: "",
    },
    trueValue: {
      // value true apabila di check, ex: ada checkbox menginap/tidak menginap, berarti trueValue nya 'menginap'
      default: true,
    },
    falseValue: {
      // refer ke trueValue
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isChecked() {
      if (this.modelValue instanceof Array) {
        return this.modelValue.includes(this.trueValue);
      }
      return this.modelValue === this.trueValue;
    },
  },
  methods: {
    updateInput(event) {
      let isChecked = event.target.checked;
      if (this.modelValue instanceof Array) {
        let newValue = [...this.modelValue];
        if (isChecked) {
          newValue.push(this.trueValue);
        } else {
          newValue.splice(newValue.indexOf(this.trueValue), 1);
        }
        this.$emit("change", newValue);
      } else {
        this.$emit("change", isChecked ? this.trueValue : this.falseValue);
      }
    },
  },
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex items-center"},[_c('input',{staticClass:"checked:bg-primary focus:ring-white text-primary",class:{
      'h-6 w-6 text-base': _vm.size == 'md',
      'h-8 w-8 text-lg': _vm.size == 'lg',
      'h-10 w-10 text-xl': _vm.size == 'xl',
      'border border-primary hover:shadow-primary-sm cursor-pointer':
        !_vm.disabled,
      'cursor-not-allowed': _vm.disabled || _vm.readOnly,
      'text-primary-lightest': _vm.disabled && _vm.isChecked,
      'border border-gray-lightest text-gray-lightest bg-gray-lightest':
        _vm.disabled && !_vm.isChecked,
    },attrs:{"type":"radio","name":_vm.name,"disabled":_vm.disabled || _vm.readOnly,"readonly":_vm.readOnly},domProps:{"checked":_vm.isChecked,"value":_vm.nativeValue},on:{"change":function($event){!_vm.readOnly && _vm.updateValue($event.target.value)}}}),_c('span',{staticClass:"ml-2",class:{
      'text-base': _vm.size == 'md',
      'text-lg': _vm.size == 'lg',
      'text-xl': _vm.size == 'xl',
    }},[_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }
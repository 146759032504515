var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Transition',{attrs:{"name":"fade"}},[(_vm.value)?_c('div',{staticClass:"fixed inset-0 w-full flex items-center justify-center bg-black-60 backdrop-filter backdrop-blur-sm z-100",class:{
      'h-screen': !_vm.scrollable,
      'h-full overflow-auto': !_vm.scrollable,
    }},[_c('div',{staticClass:"shadow-lg p-2 z-50",class:[
        {
          'relative overflow-visible': !_vm.scrollable,
          'rounded-lg': !_vm.fullScreen,
          'bg-white': !_vm.customClass,
          'fixed max-h-screen overflow-x-hidden': _vm.scrollable,
        },
        _vm.computedWidth,
        _vm.customClass ],style:(_vm.styleObject)},[(!_vm.hideClose)?_c('button',{staticClass:"absolute top-0 right-0 text-xl font-bold text-gray-500 my-2 mx-4 text-secondary hover:text-secondary-dark",attrs:{"aria-label":"close"},on:{"click":function($event){$event.preventDefault();!_vm.hideClose && _vm.close()}}},[_c('span',{staticClass:"icon-x"})]):_vm._e(),_vm._t("default")],2)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }
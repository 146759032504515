var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"flex items-center"},[_c('label',{staticClass:"block text-sm text-gray mb-1",class:{ 'text-error': _vm.error }},[_vm._v(" "+_vm._s(_vm.label)+" ")])]),_c('div',{staticClass:"pl-0 rounded-lg border border-primary items-center focus:border-secondary focus:shadow-secondary-round flex flex-row",class:{ 'border-gray-lightest': _vm.disabled }},[_c('div',{staticClass:"w-full flex"},[_c('date-picker',{staticClass:"w-full",attrs:{"mode":"date","masks":_vm.masks,"is-range":""},on:{"input":_vm.handleInput},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var inputValue = ref.inputValue;
var inputEvents = ref.inputEvents;
return [_c('div',{staticClass:"flex w-full"},[_c('input',_vm._g({staticClass:"h-11 p-2.5 pr-0 border-none rounded-lg focus:outline-none placeholder-gray-light w-full text-gray",class:{
                'bg-gray-lightest border-gray-lightest': _vm.disabled,
              },attrs:{"disabled":_vm.disabled,"readonly":""},domProps:{"value":_vm.startDate != ''
                  ? inputValue.start + ' ~ ' + inputValue.end
                  : _vm.placeholder}},inputEvents.start)),(!_vm.disabled)?_c('svg',{staticClass:"text-gray-600 w-6 mr-2 my-2 pointer-events-none",attrs:{"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M19 4H5C3.89543 4 3 4.89543 3 6V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V6C21 4.89543 20.1046 4 19 4Z","stroke":"#666666","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M16 2V6","stroke":"#666666","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M8 2V6","stroke":"#666666","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M3 10H21","stroke":"#666666","stroke-linecap":"round","stroke-linejoin":"round"}})]):_vm._e()])]}}]),model:{value:(_vm.inputDateRange),callback:function ($$v) {_vm.inputDateRange=$$v},expression:"inputDateRange"}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }
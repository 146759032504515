<template>
  <div
    class="bg-white items-center p-4 text-gray fixed top-0 w-full z-50"
    style="font-family: 'Poppins'"
  >
    <div class="flex justify-between w-full max-h-12">
      <div class="flex items-center justify-start">
        <slot name="leftMenu"></slot>
      </div>
      <div class="flex items-center justify-center">
        <slot name="centerMenu"></slot>
      </div>
      <div class="flex items-center justify-end">
        <slot name="rightMenu"></slot>
      </div>
    </div>
    <div
      class="fixed inset-0 w-full h-screen flex items-center justify-center bg-black-40 backdrop-filter z-10"
      v-if="mobileView && showMobileMenu"
      @click="showMobileMenu = false"
    >
      <div class="fixed w-3/4 h-screen right-0 p-5 bg-white">
        <slot name="mobileMenu"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GistaToolbar",
  props: {
    showMobileMenu: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  computed: {
    mobileView() {
      this.$emit("mobile-view", this.windowWidth < 640);
      return this.windowWidth < 640;
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
};
</script>

<template>
  <div class="font-poppins">
    <div
      class="hidden fixed md:flex flex-wrap content-between left-0 gap-4 overflow-auto top-0 w-28 px-6 py-12 h-full items-center p-4 text-gray"
    >
      <div class="flex flex-wrap gap-16 justify-center">
        <router-link to="/">
          <img src="./gista.svg" width="60" />
        </router-link>
        <router-link to="/profile">
          <div
            class="initial transition h-14 w-14 text-lg font-semibold flex items-center justify-center overflow-hidden rounded-lg"
          >
            {{ nameInitials }}
            <slot></slot>
          </div>
          <!-- <promo-avatar v-if="userInfo" :name="userInfo.name"></promo-avatar> -->
        </router-link>
        <div class="flex flex-col gap-8 items-center justify-center">
          <router-link
            to="/"
            exact
            class="route transition w-12 h-12 bg-white flex items-center justify-center rounded-lg border"
          >
            <span class="icon-home text-2xl font-semibold"></span>
          </router-link>

          <router-link
            to="/inbox"
            exact
            class="route transition w-12 h-12 bg-white flex items-center justify-center rounded-lg border"
          >
            <span class="icon-mail text-2xl font-semibold"></span>
          </router-link>
        </div>
      </div>
      <div class="flex justify-center w-full">
        <div
          class="logout transition w-12 h-12 flex items-center justify-center rounded-lg text-white cursor-pointer"
          @click="logout"
        >
          <span class="icon-power text-2xl font-semibold"></span>
        </div>
      </div>

      <div
        class="fixed inset-0 w-full h-screen flex items-center justify-center bg-black-40 backdrop-filter z-10"
        v-if="mobileView && showMobileMenu"
        @click="showMobileMenu = false"
      >
        <div class="fixed w-3/4 h-screen right-0 p-5 bg-white">
          <slot name="mobileMenu"></slot>
        </div>
      </div>
    </div>

    <modal v-model="confirmLogout" width="1/4" :hide-close="true">
      <div class="flex flex-col items-center justify-center p-5">
        <div>
          <img src="./logout.svg" width="120" />
        </div>
        <div class="mt-2 text-center">
          <h2 class="text-2xl font-bold py-4 text-error">Keluar aplikasi?</h2>
          <span class="text-gray px-4"
            >Apakah anda yakin ingin keluar dari aplikasi?</span
          >
          <div class="my-4 flex justify-center">
            <button-gista
              type="secondary"
              color="error"
              @click="confirmLogout = false"
              class="mr-2"
              >Batal</button-gista
            >
            <button-gista color="error" @click="doLogout" class="ml-2"
              >Keluar</button-gista
            >
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from "../Modal/Modal.vue";
import Button from "../Button/Button.vue";

export default {
  name: "GistaSidebar",
  components: {
    Modal,
    ButtonGista: Button,
  },
  props: {
    showMobileMenu: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      userInfo: window.keycloak.tokenParsed,
      windowWidth: window.innerWidth,
      confirmLogout: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  computed: {
    mobileView() {
      this.$emit("mobile-view", this.windowWidth < 640);
      return this.windowWidth < 640;
    },

    nameInitials() {
      let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");
      let initials = [...this.userInfo.name.matchAll(rgx)] || [];
      initials = (
        (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
      ).toUpperCase();
      return initials;
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },

    logout() {
      this.confirmLogout = true;
      // window.keycloak.logout();
    },

    doLogout() {
      window.keycloak.logout({ redirectUri: window.location.origin });
    },
  },
};
</script>

<style scoped>
.initial {
  color: #378d87;
  background-color: #85ece4;
}

.initial:hover {
  color: #378d87;
  background-color: #30d5c9;
  box-shadow: 0px 12px 24px rgba(48, 213, 201, 0.2);
}

.route {
  color: #30d5c9;
  border-color: #30d5c9;
}

.route:hover {
  background-color: #c0f7f2;
  box-shadow: 0px 12px 24px rgba(48, 213, 201, 0.2);
}

.route.router-link-active {
  background: #30d5c9 !important;
  color: #fff !important;
}

.logout {
  background-color: #df1642;
  box-shadow: 0px 4px 8px rgba(223, 22, 66, 0.12);
}

.logout:hover {
  background-color: #b80229;
  box-shadow: 0px 12px 24px rgba(223, 22, 66, 0.2);
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'overflow-x': _vm.scrollX,
  }},[(_vm.searchable)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"form-input w-1/2 hover:shadow-primary-sm focus:ring-0 focus:shadow-primary focus:border-primary shadow-primary border-primary rounded-lg mb-4",attrs:{"type":"text","placeholder":"Search..."},domProps:{"value":(_vm.search)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.search=$event.target.value},_vm.handleSearch]}}):_vm._e(),_c('div',{class:{
      'overflow-scroll w-full': _vm.scrollX,
    }},[_c('table',{staticClass:"min-w-full divide-y divide-gray-200 table-fixed dark:divide-gray-700"},[_c('thead',{staticClass:"bg-white text-gray-darkest"},[_c('tr',_vm._l((_vm.headers),function(head,i){return _c('th',{key:i,staticClass:"px-5 py-3 border-b-2 border-primary bg-gray-100 text-sm font-semibold text-gray-700 tracking-wider",class:{
              'text-left': head.align === 'start',
              'text-center': head.align === 'center',
              'text-right': head.align === 'end',
            }},[_c('span',{staticClass:"relative",on:{"click":function($event){head.sortable && _vm.sortBy($event, head.value)}}},[_vm._v(" "+_vm._s(head.text)+" "),_c('span',{class:{
                  'sort-desc': _vm.findSortDirection(head.value) === 'desc',
                  'sort-asc': _vm.findSortDirection(head.value) === 'asc',
                }})]),(_vm.findSort(head.value) && _vm.sortOrder.length > 1)?_c('button',{staticClass:"rounded-full h-5 w-5 inline-flex justify-center items-center bg-primary-lightest ml-1 text-xs text-primary-dark",on:{"click":function($event){return _vm.removeSorting(head.value)}}},[_vm._v(" "+_vm._s(_vm.findSortIndex(head.value) + 1)+" ")]):_vm._e()])}),0)]),_c('tbody',{staticClass:"text-gray-dark"},[(_vm.showNotFound && _vm.tableData.length < 1)?_c('tr',[_c('td',{staticClass:"bg-primary-lightest bg-opacity-25 text-center p-4",attrs:{"colspan":_vm.headers.length}},[_vm._v(" "+_vm._s(_vm.notFoundLabel)+" ")])]):_vm._e(),_vm._l((_vm.tableData),function(data,i){return _c('tr',{key:i,class:[
            {
              'bg-primary-lightest bg-opacity-25':
                _vm.rowClass.length < 1 && i % 2 === 0,
              'bg-white': _vm.rowClass.length < 1 && i % 2 !== 0,
            },
            _vm.rowClass(data, i) ]},_vm._l((_vm.headers),function(head){return _c('td',{key:head.value,staticClass:"p-4",class:[
              {
                'text-left': head.align === 'start',
                'text-center': head.align === 'center',
                'text-right': head.align === 'end',
              },
              head.width ]},[_c('div',{class:['flex', head.width, _vm.wordWrap(head.width)]},[_vm._t(head.value,function(){return [_vm._v(_vm._s(data[head.value]))]},{"slotProps":{ data: data, index: _vm.getCurrentIndex(i) }})],2)])}),0)})],2)])]),(_vm.showPagination)?_c('div',{staticClass:"form-group text-gray-dark space-x-1"},[_c('PaginationGista',{attrs:{"current":_vm.currentPage,"total":_vm.totalRecords,"per-page":_vm.pageCount,"paging":_vm.paging},on:{"page-changed":function($event){return _vm.handlePageChange($event)},"paging-change":function($event){return _vm.handlePagingChange($event)}}},[_c('template',{slot:"footer-action"},[_vm._t("footer-action")],2)],2)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="h-screen" :class="[{ fullscreen: fullScreen }, 'frame']">
    <div
      :class="[
        'bg-white relative overflow-hidden frame-body shadow-primary-md',
      ]"
    >
      <span
        v-if="hover"
        class="tooltip-maximize fixed text-xs font-medium text-white p-1 rounded z-100"
        :class="fullScreen ? 'top-12 right-2' : 'top-3 right-5'"
      >
        {{ fullScreen ? "Minimize" : "Fullscreen" }}
      </span>
      <button
        @mouseover="hover = true"
        @mouseleave="hover = false"
        class="absolute top-4 right-4 z-40 opacity-50 hover:opacity-100 hidden md:block"
        @click.prevent="toggleFullscreen"
      >
        <div class="text-2xl font-bold button-maximize">
          <span v-if="!fullScreen" class="icon-maximize"></span>
          <span v-else class="icon-minimize"></span>
        </div>
      </button>

      <div
        ref="scrollArea"
        id="scrollArea"
        class="main-container overflow-auto h-full"
      >
        <!-- <div class="relative"> -->
        <slot></slot>
        <!-- </div> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "GistaFrame",
  data() {
    return {
      fullScreen: false,
      hover: false,
    };
  },
  methods: {
    toggleFullscreen() {
      this.$emit("fullscreen", this.fullScreen);
      this.fullScreen = !this.fullScreen;
    },
  },
};
</script>
<style scoped lang="scss">
.button-maximize {
  color: #797ef6;
}

.tooltip-maximize {
  background-color: #797ef6;
}

#scrollArea {
  scroll-behavior: smooth;
}

#scrollArea::-webkit-scrollbar {
  width: 8px;
}

#scrollArea::-webkit-scrollbar-thumb {
  border-radius: 16px;
  background: #6de6dd;
  // box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
}

#scrollArea::-webkit-scrollbar-thumb:hover {
  background: #30d5c9;
}

#scrollArea::-webkit-scrollbar-track {
  border-radius: 16px;
  background: #e3fffd;
  // box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
}

@media (max-width: 768px) {
  .frame-body {
    height: 100vh;
    border-radius: 0;
  }
}
@media (min-width: 768px) {
  .frame {
    padding: 24px 24px 24px 112px;
    overflow: hidden;
    transition: padding ease-in-out 100ms;

    &-body {
      height: calc(100vh - 48px);
      border-radius: 20px;
      transition: height ease-in-out 100ms, border-radius ease-in-out 100ms;
    }

    &.fullscreen {
      padding: 0;

      .frame-body {
        height: 100vh;
        border-radius: 0;
      }
    }
  }
}
</style>

<template>
  <div class="flex flex-col">
    <div class="flex items-center">
      <label
        class="block text-sm text-gray mb-1"
        :class="{ 'text-error': error }"
      >
        {{ label }}
      </label>
    </div>
    <div
      class="pl-0 rounded-lg border border-primary items-center focus:border-secondary focus:shadow-secondary-round flex flex-row"
      :class="{ 'border-gray-lightest': disabled }"
    >
      <div class="w-full flex">
        <date-picker
          v-model="inputDateRange"
          mode="date"
          :masks="masks"
          @input="handleInput"
          is-range
          class="w-full"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <div class="flex w-full">
              <input
                class="h-11 p-2.5 pr-0 border-none rounded-lg focus:outline-none placeholder-gray-light w-full text-gray"
                :class="{
                  'bg-gray-lightest border-gray-lightest': disabled,
                }"
                :value="
                  startDate != ''
                    ? inputValue.start + ' ~ ' + inputValue.end
                    : placeholder
                "
                :disabled="disabled"
                v-on="inputEvents.start"
                readonly
              />
              <svg
                v-if="!disabled"
                class="text-gray-600 w-6 mr-2 my-2 pointer-events-none"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19 4H5C3.89543 4 3 4.89543 3 6V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V6C21 4.89543 20.1046 4 19 4Z"
                  stroke="#666666"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16 2V6"
                  stroke="#666666"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8 2V6"
                  stroke="#666666"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M3 10H21"
                  stroke="#666666"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </template>
        </date-picker>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "v-calendar/lib/components/date-picker.umd";
export default {
  name: "DatepickerRangeGista",
  components: {
    DatePicker,
  },
  data() {
    return {
      dateRange: { start: "", end: "" },
      masks: {
        input: "DD/MM/YYYY",
      },
    };
  },
  props: {
    minDate: {
      type: String,
      default: "",
    },
    maxDate: {
      type: String,
      default: "",
    },
    startDate: {
      type: String,
      default: "",
    },
    endDate: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "Select a date range",
    },
    label: {
      type: String,
      default: "",
    },
    format: {
      type: String,
      default: "DD/MM/YYYY",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    inputDateRange: {
      default: {
        start: "",
        end: "",
      },
    },
  },
  methods: {
    handleInput(value) {
      this.$emit("input", value);
    },
  },
};
</script>

<template>
  <button
    class="flex items-center justify-center transition"
    :class="[cursor, sizeButton, buttonColor, customClass]"
    @click="click($event)"
    :disabled="disabled"
  >
    <slot name="icon-left" class="mr-2"></slot>
    <slot></slot>
    <slot name="icon-right" class="mr-2"></slot>
  </button>
</template>

<script>
/*
  <button-gista
    type="secondary"
    color="error"
    flat
    @click="closeModal"
    :disabled="isLoading"
  >
    Cancel
  </button-gista>
*/

export default {
  name: "ButtonGista",

  props: {
    size: {
      //small, normal, large
      type: String,
      default: "normal",
    },

    type: {
      //primary, secondary,tertiary (refer to figma)
      type: String,
      default: "primary",
    },

    color: {
      //using color stated in tailwind config
      type: String,
      default: "primary",
    },

    isDark: {
      //if type is primary & isDark false, then the text will be gray
      type: Boolean,
      default: true,
    },

    disabled: {
      // if true then the button cannot be clicked
      type: Boolean,
      default: false,
    },

    icon: {
      //if true then padding x & y will same (symetrical)
      type: Boolean,
      default: false,
    },

    rounded: {
      //if true rounded-full
      type: Boolean,
      default: false,
    },

    expanded: {
      // if true -> w-full
      type: Boolean,
      default: false,
    },

    flat: {
      // if true -> button will flat, don't have shadow
      type: Boolean,
      default: false,
    },

    outlined: {
      // if true -> button will have outlined color and white background
      type: Boolean,
      default: false,
    },

    customClass: {
      // to customize button
      type: String,
      default: "",
    },
  },

  computed: {
    cursor() {
      if (this.disabled) return "cursor-not-allowed";
      return "cursor-pointer";
    },

    sizeButton() {
      switch (this.size) {
        case "normal":
          return `font-semibold ${
            this.icon ? "text-lg w-14 h-12" : "text-base py-3 px-6"
          } ${this.rounded ? "rounded-full" : "rounded-lg"} ${
            this.expanded ? "w-full" : ""
          }`;
        case "small":
          return `${
            this.icon
              ? "font-semibold text-base w-10 h-10"
              : "font-medium text-sm py-2 px-4"
          } ${this.rounded ? "rounded-full" : "rounded-md"} ${
            this.expanded ? "w-full" : ""
          }`;
        case "large":
          return `font-bold ${
            this.icon ? "text-2xl w-17 h-17" : "text-xl py-4 px-8"
          } ${this.rounded ? "rounded-full" : "rounded-xl"} ${
            this.expanded ? "w-full" : ""
          }`;
        default:
          return `text-base font-semibold ${
            this.icon ? "w-14 h-12" : "py-4 px-8"
          } ${this.rounded ? "rounded-full" : "rounded-lg"} ${
            this.expanded ? "w-full" : ""
          }`;
      }
    },

    textColor() {
      switch (this.type) {
        case "primary":
          return this.isDark ? "text-white" : "text-gray";
        case "secondary":
          return this.disabled
            ? `text-${this.color}-light`
            : `text-${this.color} hover:text-${this.color}-dark active:text-${this.color}-darkest`;
        case "tertiary":
          return this.disabled
            ? `text-${this.color}-light`
            : `text-${this.color} hover:text-${this.color}-dark active:text-${this.color}-darkest`;
        default:
          return this.isDark ? "text-white" : "text-gray";
      }
    },

    buttonColor() {
      switch (this.type) {
        case "primary":
          return this.disabled
            ? `bg-${this.color}-light ${this.textColor}`
            : `bg-${this.color} ${
                this.outlined ? `border border-${this.color} bg-white` : ""
              } hover:bg-${this.color}-dark active:bg-${this.color}-darkest ${
                this.textColor
              } ${
                this.flat
                  ? ""
                  : `shadow-${this.color}-sm hover:shadow-${this.color}-lg active:shadow-${this.color}-sm`
              }`;
        case "secondary":
          return this.disabled
            ? `bg-white ${this.textColor} ${
                this.size === "large" ? `border-2` : `border`
              } border-${this.color}-light`
            : `bg-white hover:bg-${this.color}-lightest ${this.textColor} ${
                this.size === "large" ? `border-2` : `border`
              } border-${this.color} hover:border-${
                this.color
              }-dark active:border-${this.color}-darkest ${
                this.flat
                  ? ""
                  : `shadow-${this.color}-sm hover:shadow-${this.color}-lg active:shadow-${this.color}-sm`
              }`;
        case "tertiary":
          return this.disabled
            ? `bg-gray-lightest ${this.textColor}`
            : `bg-transparent hover:bg-${this.color}-lightest ${
                this.textColor
              } ${
                this.flat
                  ? ""
                  : `hover:shadow-${this.color}-lg active:shadow-${this.color}-sm`
              }`;
        default:
          return this.disabled
            ? `bg-${this.color}-light ${this.textColor}`
            : `bg-${this.color} hover:bg-${this.color}-dark active:bg-${
                this.color
              }-darkest ${this.textColor} ${
                this.flat
                  ? ""
                  : `shadow-${this.color}-sm hover:shadow-${this.color}-lg active:shadow-${this.color}-sm`
              }`;
      }
    },
  },

  methods: {
    click(event) {
      this.$emit("click", event);
    },
  },
};
</script>
